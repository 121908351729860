import FieldContext from 'rc-field-form/lib/FieldContext'
import { useContext } from 'react'

import { WrappedInputProps } from '../components/Form'

type HookProps = Pick<WrappedInputProps, 'name' | 'listName'>

export function useFormValues({ name, listName }: HookProps) {
    const { setFieldsValue, getFieldValue, validateFields } = useContext(FieldContext)

    const setFormValue = (value: unknown, filedName?: string | [number, string]) => {
        const _name = filedName || name

        if (listName && Array.isArray(_name)) {
            const valuesArray = getFieldValue(listName)
            Object.assign(valuesArray[_name[0]], { [_name[1]]: value })
            setFieldsValue({ [listName]: valuesArray })
        }

        if (typeof _name === 'string') {
            setFieldsValue({ [_name]: value })
        }
    }

    const getFormValue = (filedName?: string | [number, string]) => {
        const _name = filedName || name

        if (listName && Array.isArray(_name)) {
            const valuesArray = getFieldValue(listName)
            return valuesArray[_name[0]]?.[_name[1]]
        }

        if (typeof _name === 'string') {
            return getFieldValue(_name)
        }
    }

    const validateField = () => {
        if (listName && Array.isArray(name)) {
            validateFields([[listName, ...name]])
        }

        if (typeof name === 'string') {
            validateFields([name])
        }
    }

    return { setFormValue, getFormValue, validateField }
}
